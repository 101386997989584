<template>
  <div>
    <div v-if="this.$route.name === 'Product'">
      <Product />
    </div>
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>
import Product from "@/views/companyApp/product/Product.vue";

export default {
  name: 'ProductParent',
  components: {
    Product
  }
}
</script>
