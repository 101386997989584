<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
          >{{ $t("product.productList") }}</span
        >
        <CommonAdd
          v-if="accessRight.includes('create')"
          :name="$t('product.product')"
          pathName="addItem"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <ProductListTable
          :listSkeleton.sync="listSkeleton"
          @redirectOnEditPage="redirectOnEditPage"
          @deleteItem="deleteItem"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ProductListTable from "./ProductListTable";
import CommonAdd from "@/components/CommonAdd";

export default {
  name: "Product",
  components: {
    ProductListTable,
    CommonAdd,
  },
  computed: {
    ...mapGetters(["accessRight"]),
  },
  data() {
    return {
      listSkeleton: false,
    };
  },
  methods: {
    redirectOnEditPage(item) {
      const path = "item/editItem/" + item.id;
      const windowName = "itemTab_" + item.id;
      window.open(path, windowName);
    },
    deleteItem(item) {
      this.$store.dispatch("product/DeleteProductItemById", item.id);
    },
  },
};
</script>
